//rp-table
@mixin make-rp-table($rp-table-info : $g_rp-table) {
  @each $key, $val in ($rp-table-info){
    .rp-table-#{$key} {
      @each $key2, $val2 in $val {
        @if(type-of($val2) != map) {
          #{$key2} : #{$val2};
        }@else {
          .#{$key2} {
            @each $key3, $val3 in $val2 {
              @if(type-of($val3) != map) { //cascading으로 먼저 출력
                  #{$key3}: #{$val3};
              }@else {
                @if($key3 == media){
                  @each $key4, $val4 in $val3 {
                    @include media-query-min($key4) {
                      $codes: makeMapToCodes($val4);
                      @include printCodes($codes);
                    }
                  }
                }@else if($key3 == "&") {
                  $codes: makeMapToCodes($val3, "", true);
                  @include printCodes($codes);
                }@else {
                  .#{$key3} {
                    @include printCodes($val3);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}








