// Button variants
// 버튼 사이즈별 생성
@mixin button-size-px($btn-size : $g_btn-size) {
  // Base styles
  %btn {
    display: inline-block;
    width: 100%;
    padding: 0;
    border: none;
    outline: 0;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    white-space: nowrap;
    cursor: pointer;
    -webkit-appearance: none;
    outline: none;
    transition:background-color .3s linear,border .3s linear;
  }
  @each $key, $val in ($btn-size){
    .btn-#{$key} {
      @extend %btn;
      @each $key2, $val2 in $val {
        @if ($key2 == media) {
          @each $key3, $val3 in $val2 {
            @include media-query-min($key3) {
              $codes: makeMapToCodes($val3);
              @include printCodes($codes);
            }
          }
        } @else{
          @if ( type-of($val2) == map ) { // one depth class 안에 추가 class 생성
            .#{$key2} {
              $codes: makeMapToCodes($val2);
              @include printCodes($codes);
            }
          } @else { // one depth class 버튼
            #{$key2}: #{$val2};
          }
        }
      }
    }
  }
}

@mixin btn-disabled() {
  &.disabled {
    @content;
    pointer-events:none;
  }
}

// 버튼 스타일 설정해주기
@mixin button-style($btn-group : $g_btn-group){
  @each $key, $val in $btn-group {
    .btn-#{$key},
    .btn-#{$key}:visited{
      @each $key2, $val2 in $val {
        @if ($key2 == media) { // media
          @each $key3, $val3 in $val2 {
            @include media-query-min($key3) {
              $codes: makeMapToCodes($val3);
              @include printCodes($codes);
            }
          }
        }@else if($key2 == hover){ // hover
          @include hover-on {
            @each $key4, $val4 in $val2 {
              // @if($key4 != background-color){ // background-color 이외의 나머지 출력
                #{$key4}: #{$val4};
              // }
            }
            // @if(map_has_key(map-get($val,hover),background-color)){ //hover map에 background-color 값이 있으면 그것을 출력
            //   background-color: map-get($val2,background-color);
            // }
            // @else if(map_has_key($val,background-color)){ //없으면 기본 설정값에 darken으로 적용
            //   background-color:darken(map-get($val,background-color), 15%);
            // }
          }
        }@else if($key2 == disabled){ // disabled
          @include btn-disabled {
            @if ( type-of($val2) == map ) {
              @each $key5, $val5 in $val2 {
                #{$key5}: #{$val5};
              }
            }
          }
        }@else{
          @if ( type-of($val2) == map ) { // one depth class 안에 추가 class 생성
            .#{$key2} {
              $codes: makeMapToCodes($val2);
              @include printCodes($codes);
            }
          } @else { // one depth class 코드 출력
            #{$key2}: #{$val2};
          }
        }
      }
    }
  }
}

