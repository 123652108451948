// triangle(▶), arrow(>) 사용법
// - 방향 : top, bottom, left, right
// (triangle(▶)은 top-left/right, bottom-left/right 방향도 가능)
// @include triangle("삼각형방향", 가로px, 세로px, #컬러);
// @include arrow("화살표방향", 가로px, 세로px, 선굵기, #컬러);
// 참고 url : http://apps.eky.hk/css-triangle-generator/

// 삼각형 ▶
@mixin triangle($direction, $widthSize, $heightSize, $color) {
  content: "";
  display:inline-block;
  height: 0;
  width: 0;
  border-style: solid;
  border-color: transparent;
  @if $direction=="top" {
      border-bottom-color: $color;
      border-width: 0 ($widthSize / 2) $heightSize;
  } @else if $direction == "top-right" {
      border-right-color: $color;
      border-width: 0 $widthSize $heightSize 0;
  } @else if $direction == "right" {
      border-left-color: $color;
      border-width: ($heightSize / 2) 0 ($heightSize / 2) $widthSize;
  } @else if $direction == "bottom-right" {
      border-bottom-color: $color;
      border-width: 0 0 $widthSize $widthSize;
  } @else if $direction == "bottom" {
      border-top-color: $color;
      border-width: $heightSize ($widthSize / 2) 0;
  } @else if $direction == "bottom-left" {
      border-left-color: $color;
      border-width: $widthSize 0 0 $widthSize;
  } @else if $direction == "left" {
      border-right-color: $color;
      border-width: ($heightSize / 2) $widthSize ($heightSize / 2) 0;
  } @else if $direction == "top-left" {
      border-top-color: $color;
      border-width: $widthSize $widthSize 0 0;
  }
}
// 화살표 >
@mixin arrow($direction, $width, $height, $border, $color) {
  content:"";
  display: inline-block;
  width: floor($width / 1.414);
  height: floor($height*($width/$height) / 1.414);
  border-top: $border solid $color;
  border-right: $border solid $color;
  @if $direction == "top" {
      transform: rotate(-45deg);
  } @else if $direction == "right"{
      transform: rotate(45deg);
  } @else if $direction=="left" {
      transform: rotate(-135deg);
  } @else if $direction == "bottom" {
      transform: rotate(135deg);
  }
}

//적용 후, margin이나 trasnform으로 위치 조절.