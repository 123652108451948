
// _pagination 
$g_page-item-group : ( // (명칭 : a 태그 page-link 로(스타일)  / page-item 이 각자의 wrap (간격조정)/ 전체를 .pagination이 감싸고 있음)
  // default
  // width:20px,
  height:20px,

  //padding:20px,
  // margin:0 -1px,
  // theme-color: #fff,
  // border : 1px solid #222,
  //border-radius: 3px,
  //border-radius-f-l: 3px, // 첫번째 마지막 버튼 코너 radius
  //border-right : none, // 버튼이 붙어있는 경우
  //border-style: ( // last-child에 적용됨
  //	border-right : "1px solid #e1e1e1",
  //),

  font-size:13px,
  font-weight:400,
  // letter-spacing:-0.05em,
  font-color:#aaa,
  font-family:$font-lato,
  // hover
  hover-theme-color:#fff,
  // hover-border-color : #ddd,
  hover-color:#000,

  // active
  // active-theme:#f2f2f2,
  active-color:#000,
  active-style: ( // 커스텀용
    /* border-bottom: 1px solid $g_point-theme-color, */
    font-weight:bold,
  ),

  // disabled
  dim-opacity : .5,
  //disabled-style: ( // 커스텀용
  //	//버튼에서는 map-has-key값을 이용해서 키값이 잇으면 맵의 키값과 벨류로 출력
  //	//페이지네이션에서는 키값이 있으면 안에 내용 그대로 출력..
  //	//결과 값은 같으나 mixin방법이 다르다 현재
  //	background-color:#111,
  //	color: #111,
  //	border-bottom:"2px solid #02388b",
  //),
  arrow-group : (
    start : ( //커스텀
      width: "100%",
      height: "100%",
      background-image :url(/views/res/imgs/common/common_arrow_icon_start.svg),
      background-position:center,
      background-repeat:no-repeat,
    ),
    prev : (
      width: "100%",
      height: "100%",
      background-image :url(/views/res/imgs/board/pagination_prev_icon.png),
      background-position:center,
      background-repeat:no-repeat,
    ),
    next : (
      width: "100%",
      height: "100%",
      background-image :url(/views/res/imgs/board/pagination_next_icon.png),
      background-position:center,
      background-repeat:no-repeat,
    ),
    end : (
      width: "100%",
      height: "100%",
      background-image :url(/views/res/imgs/common/common_arrow_icon_end.svg),
      background-position:center,
      background-repeat:no-repeat,
    ),
  ),
);
