// _table
$g_rp-table: (
  style1: (
    font-family:$font-dotum,
    color:#222,
    rp-table: (
      border-top: 1px solid #222
    ),
    rp-thead: (
      display: none,
      media: (
        md: (
          display: table,
          width: 100%,
          border-bottom: 1px solid #ddd,
          padding: 0 20px,
        ),
      )
    ),
    rp-th: (
      media: (
        md: (
          display: table-cell,
          padding: 18px 5px,
          font-size: 12px,
          color: #888,
        ),
      )
    ),
    rp-tr: (
      display: block,
      padding: 9px 5px,
      border-bottom: 1px solid #ddd,
      transition: background-color .5s,
      // "&" : (
      //   ":hover" : (
      //     background-color: #fafafa,
        // ),
        // ".none-hover:hover" : (
        //   background-color: #fff,
        // ),
      // ),
      media: (
        md: (
          display: table,
          width: 100%,
          padding: 0 20px,
          table-layout: fixed,
        ),
      )
    ),
    rp-td: (
      display: inline-block,
      padding: 5px,
      background-color: transparent,
      font-size: 11px,
      "&" : (
        ".td-block" : (
          display : block
        )
      ),
      media: (
        md: (
          display: table-cell,
          width: 100%,
          padding: 16px 5px,
          font-size: 12px,
          vertical-align: middle,
          "&" : (
            ".td-block" : (
              display : table-cell
            )
          )
        ),
      )
    ),
  )
);
