// _card
$g_card-wrap: (
  default: (
    wrap: (
      padding: 0 30px,
      background-color: #fff,
      margin-bottom: 30px,
      border-style: ( border: "1px solid #e7e9ee" ),
      border-radius: 5px
    ),
    header: (
      padding: 25px 0,
      background-color: #fff,
      border-style: ( border-bottom: "1px solid #e7e9ee" ),
      font-size: 16px
    ),
    body: (
      padding: 30px 0,
      background-color: #fff
    ),
    footer: (
      padding: 25px 0,
      background-color: #fff,
      border-style: ( border-top: "1px solid #e7e9ee" )
    )
  )
);
