// make-area 믹스인
// 기본값이 null로 셋팅되어 있는 요소는 인자가 전달되지 않을 시 컴파일 되지 않습니다.
// [매개변수]
// $max-width 기본값: null
// $gutter-width 기본값: null
// $width 기본값: 100%
// ex) @include make-area( $max-width: $max-width, $gutter-width: $area-gutter-width );
// ex) @include make-area( $width: 1600px );
//@mixin make-area( $max-width: null, $gutter-width: null, $width: 100% ) {
//  position: relative;
//  width: $width;
//  max-width: $max-width;
//  margin-right: auto;
//  margin-left: auto;
//  padding-right: $gutter-width;
//  padding-left: $gutter-width;
//}

@mixin make-area( $area-info: $g_default-area ) {
  $width: 100%;
  @if  map-has-key($area-info, width) {
    $width : map-get($area-info, width)
  } @else {
    $width : 100%
  }
  position: relative;
  width: $width;
  max-width: map-get($area-info, max-width);
  margin-right: auto;
  margin-left: auto;
  padding-right: map-get($area-info, gutter-width);
  padding-left: map-get($area-info, gutter-width);
}

