// _button
$g_btn-size: (xs: ( // 배송조회,후기작성
    width: 50px,
    height: 37px,
    line-height: 37px,
    font-family: $font-dotum,
    font-size: 12px,
  ),
  sm: ( // 회원가입, 마이페이지, 게시판, popup btn 관련 버튼
    width: 90px,
    height: 37px,
    line-height: 37px,
    font-family: $font-dotum,
    font-size: 12px,
  ),
  md: ( //일반 페이지
    width: 210px,
    height: 55px,
    line-height: 55px,
    font-size: 15px,
  ),
  lg: ( //장바구니
    width:265px,
    height: 40px,
    line-height: 40px,
    font-size: 15px,
    font-weight:500,
  ),
  xl: ( //로그인, 회원가입, 아이디&비밀번호 찾기 플로우
    height: 50px,
    line-height: 50px,
    font-size: 15px,
    font-weight:500,
    media: (md: (height: 55px,
        line-height: 55px,
      ))),
  full-sm : ( // 게시판에서 사용
    width:100%,
    height: 42px,
    line-height: 42px,
    font-family: $font-dotum,
    font-size: 12px,
    media : (md : (width: 90px,
        height: 37px,
        line-height: 37px,
      ),
    )),
  full-md: ( //일반 페이지
    width: 100%,
    max-width: 210px,
    height: 55px,
    line-height: 55px,
    font-size: 15px,
  ),
  popup: ( //popup 버튼
    max-width:210px,
    line-height: 55px,
    height: 55px,
  ),
  full:(width: 100%,
  ),
);

$g_btn-group : ( // 버튼 스타일 설정하기
  point : ( // default
    background-color: $g_point-theme-color2,
    color: #fff !important,
    hover : (background-color:#0b2d67,
    )),
  sub-point :(background-color:$g_point-theme-color,
    color: #fff !important,
    hover : (background-color:#0790d0,
    )),
  style2 : ( // 회원가입, 아이디&비밀번호 찾기
    border:1px solid #ccc,
    background-color: #f0f0f0,
    color: #555,
  ),
  style3 : ( // 주문취소, 주문리스트검색
    background-color: #555,
    color: #fff,
  ),
  outline-style1 : ( // 로그인, 주문상세보기, custom2
    border: 1px solid #ddd,
    background-color:#fff,
    color:#555 !important,
    hover : (background-color:#f2f2f2,
    )),
  outline-style2 : ( // 글쓰기
    border: 1px solid #222,
    background-color:#fff,
    color:#222,
  ),
  outline-style3: (border: 1px solid #fff,
    color :#fff,
    hover: (background-color: transparent,
    )),
);