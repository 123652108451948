// _tab
$g_tab-group : ( // .tab-wrap > .tab-list
  tab-wrap-default : ( 
    display: block,
    margin-bottom:60px,
    font-size: 0,
    color: #000,
    text-align:center,
  ),
  box-style : ( 
    tab-list : ( 
      display:inline-block,
      width:140px,
      padding:13px 10px,
      border:1px solid #bbb,
      border-right: 0,
      font-size: 15px,
      color: #777777,
      background-color: #fff,
      cursor:pointer,
      active : ( //.tab-list.active
        border-color:$g_point-font-color2,
        background-color: $g_point-theme-color2,
        color: $g_white,
      ),
      "&" : (
        ":last-child" : (
          border-right:1px solid #bbb,
        )
      )
    ),
  ),
);
