// Core variables and mixins
@import "res/scss/__variables.scss";
@import "res/scss/__mixins.scss";

//IE에서 패럴렉스효과를 위한 설정
html[class*='ie'] {
  height: 100%;
  overflow-x: hidden;
  body{
    height: 100%;
    margin:0;
    padding:0;
  }
}
.parallax-mirror{
  background-color: #fff;
}
.parallax-container {
  position: relative;
  width: 100%;
  height: 600px;
  @include media-query-min(lg){
    height: 700px;
  }
  &:nth-of-type(1){
    height: 83vh;
    @include media-query-min(xl){
      height: 92vh;
    }
  }
  &.sub-bottom-wrap{
    height: 330px;
    .title, .desc{
      opacity: 0;
    }
    &.ani-txtup{
      .title{
         animation: slideUpAnimation 1s forwards;
      }
      .desc{
        animation: slideUpAnimation 1s .2s forwards;
      }
    }
  }
  //IE에서 패럴렉스효과를 위한 배경추가
  html[class*='ie'] & { 
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: 50% 0;
    &:not(.sub-bottom-wrap){
      &:nth-of-type(1){
        background-image: url(/views/res/imgs/main/bg_main_top_m.jpg);
        @include media-query-min(md){
          background-image: url(/views/res/imgs/main/bg_main_top.jpg);
        }
      }
      &:nth-of-type(2){
        background-image: url(/views/res/imgs/main/bg_phonetouch_m.jpg);
        @include media-query-min(md){
          background-image: url(/views/res/imgs/main/bg_phonetouch.jpg);
        }
      }
      &:nth-of-type(3){
        background-image: url(/views/res/imgs/main/bg_camera_m.jpg);
        @include media-query-min(md){
          background-image: url(/views/res/imgs/main/bg_camera.jpg);
        }
      }
      &:nth-of-type(4){
        background-image: url(/views/res/imgs/main/bg_sms_m.jpg);
        @include media-query-min(md){
          background-image: url(/views/res/imgs/main/bg_sms.jpg);
        }
      }
      &:nth-of-type(5){
        background-image: url(/views/res/imgs/main/bg_onestop_m.jpg);
        @include media-query-min(md){
          background-image: url(/views/res/imgs/main/bg_onestop.jpg);
        }
      }
      &:nth-of-type(6){
        background-image: url(/views/res/imgs/main/bg_linkshop_m.jpg);
        @include media-query-min(md){
          background-image: url(/views/res/imgs/main/bg_linkshop.jpg);
        }
      }
    }
  }
  .area{
    @include media-query-min(lg){
      width: 80%;
    }
  }
}
.bg-wrapper{
  width: 100%;
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  .parallax-container:nth-of-type(1) & {
    @include media-query-min(1024px){
      background-position-x: 59% !important;
    }
    @include media-query-min(xl){
      background-position-x: center !important;
    }
  }
}
.back-gradient{
  // 링크샵 섹션에서의 배경
  @include media-query-min(lg){
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background: url(/views/res/imgs/main/bg_linkshop_back.png) no-repeat right center / contain;
  }
}
.inner-content{
  display: flex;
  height: 100%;
  align-items: center;
  color: #000;
  .parallax-container:nth-of-type(1) & {
    align-items: flex-start;
    padding-top: 14.5vh;
    @include media-query-min(sm){
      padding-top: 25vh;
    }
    @include media-query-min(lg){
      align-items: center;
      padding-top: 0;
    }
  }
  .font-white{
    color: #fff;
    .sub-title{
      color: #fff;
      .bar{
        background-color: #fff;
      }
    }
    .main-sec-desc{
      color: #fff;
    }
  }
  .right-align{
    .content-wrap{
      padding-right: 15px;
      padding-left: 0;
    }
    .sub-title{
      @include media-query-min(lg){
        margin-left: 0;
        margin-right: -21px;
      }
      .bar{
        margin-left: 11px;
        margin-right: 0;
      }
    }
  }
  .main-top-title{
    font-size: 30px;
    line-height: 1.41;
    text-align: center;
    letter-spacing: -0.05em;
    @include media-query-min(sm){
      font-size: 34px;
    }
    @include media-query-min(lg){
      font-size: 40px;
      line-height: 1.38;
      font-weight: 300;
    }
    @include media-query-min(lg){
      text-align: left;
      letter-spacing: -0.03em;
    }
    @include media-query-min(xl){
      font-size: 50px;
    }
    .point{
      color: #164dac;
      font-weight: 400;
    }
    .text{
      opacity: 0;
    }
    &.ani-on{
      .text{
        &:nth-of-type(1){
          animation: slideUpAnimation 1s  forwards;
        }
        &:nth-of-type(2){
          animation: slideUpAnimation 1s .2s forwards;
        }
        &:nth-of-type(3){
          animation: slideUpAnimation 1s .4s forwards;
        }
      }
    }
  }
  .sub-title{
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 700;
    font-family: $font-lato;
    text-transform: uppercase;
    color: #555;
    @include media-query-min(lg){
      margin-left: -25px;
      margin-bottom: 20px;
      font-size: 13px;
    }
    .img-wizzpay{
      margin-right: 5px;
      width: 51px;
      height: 10px;
      vertical-align: middle;
      @include media-query-min(lg){
        width: 65px;
        height: 13px;
      }
    }
    .bar{
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 2px;
      width: 40px;
      height: 1px;
      background-color: #555;
      vertical-align: middle;
      @include media-query-min(lg){
        width: 50px;
      }
    }
  }
  .content-wrap{
    padding-left: 15px;
    padding-right: 0;
    @include media-query-min(lg){
      padding: 0 !important;
    }
  }
}
.main-sec-title {
  margin-bottom: 12px;
  font-size: 33px;
  line-height: 1.36;
  opacity: 0;
  @include media-query-min(lg){
    margin-bottom: 18px;
    font-size: 40px;
    line-height: 1.32;
  }
  .parallax-container.ani-txtup & {
     animation: slideUpAnimation 1s forwards;
  }
  .parallax-container:nth-of-type(2) &,
  .parallax-container:nth-of-type(3) & {
    letter-spacing: -0.05em;
    @include media-query-min(lg){
      letter-spacing: 0;
    }
  }
}
.main-sec-desc {
  margin-bottom: 37px;
  font-size: 15px;
  font-weight: 300;
  line-height: 1.533;
  letter-spacing: -0.03em;
  color: #333;
  opacity: 0;
  @include media-query-min(lg){
    margin-bottom: 45px;
    font-size: 17px;
    letter-spacing: 0;
  }
  .parallax-container.ani-txtup & {
     animation: slideUpAnimation 1s .2s forwards;
  }
}
.mainBtn {
  opacity: 0;
  .parallax-container.ani-txtup & {
    animation: slideUpAnimation 1s .4s forwards;
  }
}
// 우측 current num line bar
.current-line-bar{
  display: none;
  position: fixed;
  right: 4%;
  top: 50%;
  transform: translateY(-50%);
  font-size: 13px;
  font-family: $font-lato;
  text-align: center;
  transition: color .5s;
  @include media-query-min(lg){
    display: block;
  }
  &.v-none{
    visibility: hidden;
  }
  .bar-wrap{
    margin: 22px 0;
    position: relative;
    display: inline-block;
    width: 2px;
    height: 240px;
    overflow: hidden;
    background-color: rgba(0,0,0,0.2);
    transition: background-color .5s;
    .bar{
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      width: 2px;
      height: 100%;
      background-color: #000;
      transform: scaleY(0.166);
      transform-origin: top center;
      -webkit-transform-origin: top center;
      transition: background-color .5s, transform .5s;
    }
  }
  &.white {
    color: #fff;
    .bar-wrap{
      background-color: rgba(255,255,255, 0.3);
      .bar{
        background-color: #fff;
      }
    }
  }
}
// scroll down
.scroll-hint{
  position: absolute;
  bottom: -2px;
  left:50%;
  transform: translateX(-50%);
  cursor:pointer;
  .scroll-hint-text {
    margin-bottom: 13px;
    font-size: 12px;
    font-family: $font-lato;
    font-weight: 700;
    color: #555;
  }
  .scroll-line {
    position:relative;
    display:inline-block;
    width:1px;
    height:42px;
    background-color:#d6d5d5;
    overflow:hidden;
    &:after {
      content:'';
      position:absolute;
      left:0;
      top:-25px;
      width:1px;
      height:15px;
      background-color:#000;
      animation: lineUpDownAni 1.5s cubic-bezier(0.26, 0.35, 0.68, 0.85) infinite;
    }
  }
}
@-webkit-keyframes lineUpDownAni {
  from { top:-15px}
  to { top:42px}
}
@-moz-keyframes lineUpDownAni {
  from { top:-15px}
  to { top:42px}
}
@-o-keyframes lineUpDownAni {
  from { top:-15px}
  to { top:42px}
}
@keyframes lineUpDownAni {
  from { top:-15px}
  to { top:42px}
}
