@mixin clear() {
  &::after {
     content: "";
     display: block;
     clear: both;
   }
}



