@mixin make-form-wrap( $form-wrap-info : $g_form-wrap ) {
  @each $key, $val in ($form-wrap-info){
    .form-wrap-#{$key} {
      @each $key2, $val2 in $val {
        @if(type-of($val2) != map) {
          #{$key2} : #{$val2};
        }@else {
          .#{$key2} {
            @each $key3, $val3 in $val2 {
              @if(type-of($val3) != map) { //cascading으로 먼저 출력
                  #{$key3}: #{$val3};
              }@else {
                @if($key3 == media){
                  @each $key4, $val4 in $val3 {
                    @include media-query-min($key4) {
                      $codes: makeMapToCodes($val4);
                      @include printCodes($codes);
                    }
                  }
                }@else if($key3 == "&") {
                  $codes: makeMapToCodes($val3, "", true);
                  @include printCodes($codes);
                }@else {
                  .#{$key3} {
                    $codes: makeMapToCodes($key3);
                    @include printCodes($codes);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// /*텍스트 박스*/
@mixin make-field-default() {
  %field-base {
    width: 100%;
    border: none;
    outline: none;
    vertical-align: middle;
  }
  @each $key, $val in ($g_field-style) {
    .field-#{$key} input[type="text"],
    .field-#{$key} input[type="password"],
    .field-#{$key} input[type="tel"],
    .field-#{$key} input[type="email"],
    .field-#{$key} select,
    .field-#{$key} textarea{
      @extend %field-base;
      @each $key2, $val2 in ($val) {
        @if( $key2 == "input_default") {
          @each $key3, $val3 in ($val2) {
            @if( type-of($val3) != map) {
              #{$key3} : #{$val3};
            }@else if($key3 == 'focus-style'){
              &:focus {
                @each $key4, $val4 in $val3 {
                  #{$key4} : #{$val4};
                }
              }
            }@else if($key3 == 'placeholder-style'){
              &::placeholder{
                @each $key4, $val4 in $val3 {
                  #{$key4} : #{$val4};
                }
              }
              &:-ms-input-placeholder {
                @each $key4, $val4 in $val3 {
                  #{$key4} : #{$val4};
                }
              }
              &::-ms-input-placeholder {
                @each $key4, $val4 in $val3 {
                  #{$key4} : #{$val4};
                }
              }
            }@else if ($key3 == 'media') {
              @each $key4, $val4 in $val3 {
                @include media-query-min($key4) {
                  $codes: makeMapToCodes($val4);
                  @include printCodes($codes);
                }
              }
            }
          }
        }
      }
    }
    .field-#{$key} select{
      @each $key2, $val2 in ($val) {
        @if( $key2 == "select") {
          @each $key3, $val3 in $val2 {
            @if ($key3 == media) {
              @each $key4, $val4 in $val3 {
                @include media-query-min($key4) {
                  $codes: makeMapToCodes($val4);
                  @include printCodes($codes);
                }
              }
            }@else {
              #{$key3} : #{$val3};
            }
          }
          background-repeat:no-repeat;
        }
      }
    }
    .field-#{$key} textarea{
      @each $key2, $val2 in ($val) {
        @if( $key2 == "textarea") {
          @each $key3, $val3 in $val2 {
            @if ($key3 == 'media') {
              @each $key4, $val4 in $val3 {
                @include media-query-min($key4) {
                  $codes: makeMapToCodes($val4);
                  @include printCodes($codes);
                }
              }
            }@else {
              #{$key3} : #{$val3};
            }
          }
          resize: none;
        }
      }
    }
    .field-#{$key}.no-underline {
      border-bottom: transparent;
    }
  }
}

// /*textarea*/
@mixin make-text-area() {
  @each $key, $val in ($g_text-area-style) {
    .textarea-#{$key} textarea{
      @each $key2, $val2 in $val {
        @if($key2 == media) {
          @each $key3, $val3 in $val2 {
            @include media-query-min($key3) {
              $codes: makeMapToCodes($val3);
              @include printCodes($codes);
            }
          }
        }@else {
          #{$key2} : #{$val2};
        }
      }
      resize: none;
    }
  }
}

// /*셀렉트 박스*/
@mixin make-selectbox() {
  @each $key, $val in ($g_select-style) {
    .select-#{$key} select{
      @each $key2, $val2 in $val {
        @if( type-of($val2) != map){
          #{$key2} : #{$val2};
        }@else {
          @each $key3, $val3 in $val2 {
            @include media-query-min($key3) {
              $codes: makeMapToCodes($val3);
              @include printCodes($codes);
            }
          }
        }
      }
      background-repeat:no-repeat;
    }
  }
}

// /*이미지 체크 & 라디오*/
@mixin make-checkbox-img( $check-box-info: $g_check-box-img ) {
  @each $key, $val in ($check-box-info) {
    .ckbx-#{$key}{
      @each $key2, $val2 in ($val) {
        @if(type-of($val2) != map) {
          #{$key2} : #{$val2};
        }@else {
          @if($key2 == checkbox-style) {
           .custom-checked {
              $codes: makeMapToCodes($val2);
              @include printCodes($codes);
              background-repeat: no-repeat;
              background-size: cover;
            } 
          }@else if($key2 == checked-style){
            input {
              display: none;
              &[type=radio]:checked ~ .custom-checked,
              &[type=checkbox]:checked ~ .custom-checked {
                $codes: makeMapToCodes($val2);
                @include printCodes($codes);
              }
            }
          }@else if($key2 == text-style){
            .text {
              $codes: makeMapToCodes($val2);
              @include printCodes($codes);
            }
          }
        }
      }
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &.disabled {
        pointer-events: none;
        color: #eee;
      }
    }
  }
}
// /*태그 체크& 라디오*/
@mixin make-checkbox-tag( $check-box-info: $g_check-box-tag ) {
  @each $key, $val in ($check-box-info) {
    // 변수 선언
    $width : false;
    $height : false;
    .ckbx-#{$key}{
      @each $key2, $val2 in ($val) {
        @if(type-of($val2) != map) {
          #{$key2} : #{$val2};
        }@else {
          @if($key2 == checkbox-style) {
            &:before {
              content: "";
              position: absolute;
              top: 50%;
              left: 0;
              display: inline-block;
              @each $key3, $val3 in $val2 {
                #{$key3}: #{$val3};
                @if ($key3 == width){
                  $width: $val3;
                  margin-top:-(round($val3 / 2));
                  // @include prefixer(transform, translateY(-50%), webkit);
                }
                @if ($key3 == height){
                  $height: $val3;
                }
              }
            }
          }@else if($key2 == checked-style) {
            input {
              display: none;
              &[type=radio]:checked ~ .custom-checked,
              &[type=checkbox]:checked ~ .custom-checked {
                position: absolute;
                top: 50%;
                left: round(($width - ($width / 2)) / 2);
                display: inline-block;
                content: "";
                @if(map-has-key($val2, width) == false and $width){ 
                  // checked map에 width가 있는지 체크 없으면 checkbox map 의 반사이즈로 가져옴
                  $val2 : map-set($val2,width,round($width / 2));
                }
                @if(map-has-key($val2, height) == false and $height){ 
                  // checked map에 height 있는지 체크 없으면 checkbox map 의 반사이즈로 가져옴
                  $val2 : map-set($val2,height,round($height / 2));
                }
                @each $key4, $val4 in $val2 {
                  @if ($key4 == width){
                    margin-top:-(round($val4 / 2));
                    // @include prefixer(transform, translateY(-50%), webkit);
                  }
                  #{$key4}: #{$val4};
                }
              }
            }
          }@else if($key2 == text-style){
            .text {
              $codes: makeMapToCodes($val2);
              @include printCodes($codes);
            }
          }
        }
      }
      &:last-child {
        margin-right: 0;
      }
      &.disabled{
        pointer-events: none;
        color: #eee;
        label:before{
          border-color: #eee;
        }
      }
      &.circle:before {
        border-radius: 50%;
      }
      &.circle input {
        &[type=radio]:checked ~ .custom-checked,
        &[type=checkbox]:checked ~ .custom-checked {
          border-radius: 50%;
        }
      }
      min-width: $width;
      min-height: $height;
      cursor: pointer;
    }
  }
}
