// Pagination
@mixin pagination-links($page-item){
  width:map-get($page-item, width);
  height:map-get($page-item, height);
  line-height:map-get($page-item, height); // height값과 동일

  //padding:map-get($page-item, padding); -> 삭제예정 보류..
  margin:map-get($page-item, margin);

  background-color: map-get($page-item, theme-color);
  border: map-get($page-item, border);
  border-right:map-get($page-item, border-right);

  border-radius:map-get($page-item, border-radius);
  font-size:map-get($page-item, font-size);
  font-weight:map-get($page-item, font-weight);
  font-family:map-get($page-item, font-family);
  letter-spacing:map-get($page-item, letter-spacing);
  color:map-get($page-item, font-color);

  @include hover {
    @if map-has-key($page-item, hover-theme-color) {
      background-color:map-get($page-item,hover-theme-color);
    }
    @else {
      background-color:darken( map-get($page-item, theme-color), 15%);
    }
    color:map-get($page-item, hover-color);
    border-color: map-get($page-item, hover-border-color);
  }

}
// 액티브 될시
@mixin pagination-active($page-active-item){
  background-color:map-get($page-active-item, active-theme);
  color:map-get($page-active-item,active-color);
  @each $key2, $val2 in (map-get($page-active-item,active-style)){
    #{$key2}: #{$val2};
  }
}
// 딤 될시
@mixin pagination-disabled($page-disabled-item){
  opacity:map-get($page-disabled-item, dim-opacity);
  pointer-events:none;
  @each $key2, $val2 in (map-get($page-disabled-item,disabled-style)){
    #{$key2}: #{$val2};
  }
}

// 처음 이전 다음 끝 arrow 관련 커스텀
@mixin pagination-arrows($page-arrow-style) {
  @each $key2, $val2 in $page-arrow-style {
    #{$key2}: #{$val2};
  }
}