/////default/////
//color
$g_white: #fff !default;
$g_black: #000 !default;
$g_red: #cd2000;

$g_point-font-color: #00aeef;
$g_point-font-color2: #1b458e;
$g_point-theme-color: #00aeef;
$g_point-theme-color2: #1b458e;
$g_border-color : #ddd;

//font
//-font-family
$g_font-family: (
  system: ' "맑은 고딕", "Malgun Gothic", "애플 SD 산돌고딕 Neo", "Apple SD Gothic Neo", sans-serif ',
  noto: '"Noto Sans KR", sans-serif',
  lato : '"Lato", sans-serif',
  dotum: '"Dotum", sans-serif',
);

$font-system: #{map-get($g_font-family, system)};
$font-noto: #{map-get($g_font-family, noto)};
$font-lato: #{map-get($g_font-family, lato)}; //100(T),300(L),400(R),700(B),900(B)
$font-dotum: #{map-get($g_font-family, dotum)};

//-default font 설정
$font-300 : $font-noto;
$font-400 : $font-noto;
$font-500 : $font-noto;
$font-700 : $font-noto;
$font-800 : $font-noto;

//-font-size
$g_font-size:(
  xs: 10px,
  sm: 12px,
  md: 15px,
  lg: 18px,
  xl: 24px,
);

//-font-weight
$g_font-weights: 100, 200, 300, 400, 500, 600, 700, 800, 900;

//-letter-spacing

/////media/////
$g_media-breakpoints: (
  xs: 0,
  sm: 544px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

/////layout/////
//area
$g_max-width: 1140px;
$g_area-gutter-width: 20px;

//grid
$g_grid-columns: 12;
$g_grid-gutter-width: 1%;

$g_default-area: (
  max-width: $g_max-width,
  gutter-width: $g_area-gutter-width,
  width: 100%
);
$g_full-area: (
  max-width: 1750px,
  gutter-width: $g_area-gutter-width,
  width: 100%
);
$g_nogutter-area: (
  max-width: $g_max-width
);

//grid
$g_grid-col-info: (
  grid-col-cnt: 12,
  grid-col-name: null
);
$grid-col5-info: (
  grid-col-cnt: 5,
  grid-col-name: five-
);

/////UTILITIES/////
//clear

//spacing
$spacers:(
  0: 0,
  5: 5px,
  10: 10px,
  15: 15px,
  20: 20px,
  25: 25px,
  30: 30px,
  40: 40px,
  50: 50px,
  60: 60px,
  70: 70px,
  80: 80px,
  90: 90px,
  100: 100px,
  120: 120px,
);

//===================================================================================================================//

/////COMPONENTS/////
@import "variables_components/_button";
@import "variables_components/_forms";
@import "variables_components/_cards";
@import "variables_components/_table";
@import "variables_components/_tab";
@import "variables_components/_pagination";
// @import "variables_components/_tooltip"; // 사용하지 않는 부분은 주석 ( base.scss 에도 주석 )

//===================================================================================================================//
