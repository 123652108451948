// _form
$g_form-wrap: (table : ( //.form-wrap-table (회원가입&정보, 주문결제&내역)
    background-color: #fff,
    form-wrap: ( //.form-wrap-flex .form-wrap
      display: flex,
    ),
    form-title: ( //.form-wrap-flex .form-title
      display: flex,
      flex-grow: 0,
      flex-shrink: 1,
      flex-basis: 90px,
      min-width: 90px,
      line-height:1.5,
      padding: 19px 10px,
      font-family: $font-dotum,
      font-size: 12px,
      media: (sm: (flex-basis: 100%,
          max-width:150px,
          padding: 20px 20px,
        ),
        md: (max-width:203px,
          font-size: 13px,
        ),
      ),
    ),
    form-body: ( //.form-wrap-flex .form-body
      flex-grow: 0,
      flex-shrink: 1,
      flex-basis: 403px,
      line-height:1.5,
      padding: "10px",
      font-family: $font-dotum,
      font-size: 12px,
    ),
  ),
  style1 : ( //.form-wrap-style1 (사업설명회 팝업 사용 / presentation_insert.php)
    margin-bottom: -24px,
    form-wrap: ( //.form-wrap-flex .form-wrap
      margin-bottom: 24px,
    ),
    form-title: ( //.form-wrap-flex .form-title
      margin-bottom: 8px,
      font-size: 15px,
      color: #555,
    ),
    form-body: ( //.form-wrap-flex .form-body
      font-size: 15px,
    ),
  ),
);
//_input
$g_field-style: ( //( form에서 같이 'input,select,textarea' style이 함께 사용시 )
  style1: ( //관리자
    input_default : ( //.field-style1 input ..
      height:auto,
      padding: 0 0 5px 0,
      background-color:transparent,
      border-bottom: 1px solid #d5d6d8,
      focus-style : (border:1px solid $g_point-theme-color,
      ),
      placeholder-style : (color: #cccfd6,
      ),
    ),
    select : ( //.field-style1 select ..
      background-position: right 100px bottom 50%,
      background-image: url(/views/res/imgs/common/btn_select_arrow_st01.svg),
      background-size: 12px 7px,
    ),
    textarea : ( //.field-style1 textarea ..
      height:150px,
      padding : 5px,
      border:1px solid #ddd,
    ),
  ),
  style2: ( //회원가입, 회원정보, 주문결제, 주문확인 폼
    input_default : (flex-grow: 1,
      flex-shrink: 0,
      flex-basis: 100%,
      border: 1px solid $g_border-color,
      height: 37px,
      padding: 0 10px,
      font-family: $font-dotum,
      font-size: 12px,
      letter-spacing: 0.05em,
      placeholder-style : (color: #aaa,
      ),
    ),
    select : ( // (회원가입,회원정보) 연락처
      padding: 0 20px 0 10px,
      background-position: right 5px bottom 50%,
      background-image: url(/views/res/imgs/common/btn_select_arrow_st02.svg),
      background-size: 10px 7px,
      media:(md:(padding: 0 35px 0 10px,
          background-position: right 10px bottom 50%,
        ))),
    textarea : (max-width:initial,
      height:100px,
      padding : 10px,
    )),
  style3: ( //로그인, 회원가입 플로우 //수정완료
    input_default : (border: 1px solid #ddd,
      height: 50px,
      padding: 0 10px,
      background-color: transparent,
      font-family: $font-dotum,
      font-size: 12px,
      placeholder-style : (color: #aaa,
      ),
      media: (md: (height:55px,
        ))),
    select : (overflow: hidden,
    )),
  style4: ( //(사업설명회 팝업 사용 / presentation_insert.php)
    input_default : (border: 1px solid $g_border-color,
      height: 50px,
      padding:15px 10px,
      background-color: transparent,
      font-size: 14px,
      placeholder-style : (color: #aaa,
        font-weight:300,
      ),
      media: (md: (font-size: 15px,
          padding:15px,
        ))),
    select : (padding-right: 30px,
      background-position: right 20px center,
      background-image: url(/views/res/imgs/common/common_arrow_icon_down.svg),
      background-size: 12px 7px,
    ),
  ));

//_textarea
$g_text-area-style: ( //( 페이지에서 사용시 )
  style1: ( //.textarea-style1 textarea {}
    // 사용시 css 추가
  ),
);

//_select
$g_select-style: ( //.select-style1 select {} ( 페이지에서 사용시 )
  style1: ( // 상품 리스트, 상세, 마이페이지 
    width: 100%,
    height: 50px,
    line-height: 47px,
    padding: 0 10px,
    border: 1px solid $g_border-color,
    font-size: 15px,
    color: #555,
    background-position: 92% 50%,
    background-image: url(/views/res/imgs/common/common_arrow_icon_down.svg),
    background-size: initial,
    background-color:#fff,
    media: (sm: (padding: 0 15px,
        background-position: 90% 50%,
      ))),
  style2: ( // 게시판 글쓰기 (게시물 타입)
    width: 120px,
    height: 37px,
    border: 1px solid $g_border-color,
    padding: 0 10px,
    font-size: 12px,
    color: #222,
    background-position: 90% 50%,
    background-image: url(/views/res/imgs/common/common_arrow_icon_down.svg),
    background-size: initial,
  ),
);

//_check-box
$g_check-box-img: (img-1 : (display:flex,
    align-items : flex-start,
    margin-right: 15px,
    color:#666,
    checkbox-style : (display: inline-block,
      width: 22px,
      min-width: 22px,
      height: 22px,
      background-image:url(/views/res/imgs/page/img_checkbox02_off.svg),
      vertical-align: middle,
    ),
    checked-style : (background-image: url(/views/res/imgs/page/img_checkbox02_on.svg),
    ),
    text-style : (display: flex,
      flex-wrap: wrap,
      line-height:1.5,
      padding-left:7px,
      font-size: 14px,
      check : (color:#ff8e2a,
      ),
    ),
  ),
  img-2 : (display:inline-block,
    margin-right: 15px,
    checkbox-style : (display: inline-block,
      width: 32px,
      height: 32px,
      background-image:url(/views/res/imgs/page/img_checkbox01_off.png),
      vertical-align: middle,
    ),
    checked-style : (background-image: url(/views/res/imgs/page/img_checkbox01_on.png),
    ),
    text-style : (display: inline-block,
      padding-left:4px,
      font-size: 14px,
      vertical-align: middle,
    ),
  ),
);
$g_check-box-tag: (tag-1 : ( // 아이디저장 // 약관동의 // 결제수단 //배송지 선택 // 배송지역 선택
    position: relative,
    display: inline-block,
    margin-right:30px, //self
    checkbox-style : ( // &:before
      top:9px,
      width: 22px,
      height: 22px,
      border: 1px solid #ccc,
      background-color: #fff,
    ),
    checked-style : ( //.custom-checked
      left:0,
      top:9px,
      width: 22px,
      height: 22px,
      border: 1px solid $g_point-theme-color2,
    ),
    text-style : ( //.text
      position: relative,
      display: inline-block,
      line-height: 1,
      padding-left: 31px, // 박스크기 + 박스와 텍스트 사이의 간격
      font-size: 15px,
      color: #000,
    ),
  ),
  tag-2 : (position: relative,
    display: inline-block,
    margin-right:15px, //self
    checkbox-style : ( // &:before
      width: 17px,
      height: 17px,
      border: 1px solid #ddd,
    ),
    checked-style : ( //.custom-checked
      // width:9px,
      // height: 9px,
      background-color: #555,
    ),
    text-style : ( //.text
      padding-left: 25px, // 박스크기 + 박스와 텍스트 사이의 간격
      font-size: 14px,
    ),
  ));