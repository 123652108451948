///* tab */
@mixin tab-items($tab-group : $g_tab-group) {
  @each $key, $val in ($tab-group){
    @if($key == tab-wrap-default){
      .tab-wrap {
        @each $key2, $val2 in $val {
          @if($key2 == media){
            @each $key3, $val3 in $val2 {
              @include media-query-min($key3) {
                $codes: makeMapToCodes($val3);
                @include printCodes($codes);
              }
            }
          }@else {
            #{$key2}: #{$val2};
          }
        }
      }
    }@else {
      .tab-wrap.#{$key} {
        @each $key4, $val4 in $val {
          @if(type-of($val4) != map ) { //cascading으로 먼저 출력
            #{$key4}: #{$val4};
          }@else { //media, active, subClass
            .#{$key4} {
              @each $key5, $val5 in $val4 {
                @if(type-of($val5) != map) { //cascading으로 먼저 출력
                  #{$key5}: #{$val5};
                }@else {
                  @if($key5 == media){
                    @each $key6, $val6 in $val5 {
                      @include media-query-min($key6) {
                        $codes: makeMapToCodes($val6);
                        @include printCodes($codes);
                      }
                    }
                  }@else if ($key5 == active) {
                    &.active {
                      @include printCodes($val5);
                    }
                  }@else {
                    @if($key5 == "&") {
                      $codes: makeMapToCodes($val5, "", true);
                      @include printCodes($codes);
                    }@else {
                      .#{$key5} {
                        $codes: makeMapToCodes($val5);
                        @include printCodes($codes);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
